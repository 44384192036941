

























































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import SmartSearchModal from '@/components/modals/users/SmartSearchModal.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import CourseGroupMonthChainMixin from '@/mixins/CourseGroupMonthChainMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerMastersModule from '@/store/modules/manager/masters'
import {
  IUsersFilter,
  IUsersFilterType,
  NameValueChildrenResource,
  UserManagerResource,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import { openNewTab } from '@/utils/functions'

enum MenuActions {
  EXCEL = 'excel',
  SMART_SEARCH = 'smartSearch',
}

@Component({
  components: {
    ButtonArrow,
    CourseSearch,
    DateInput,
    Parameters,
    Select,
    SmartSearchModal,
    TableFooter,
    Tag,
    TextInput,
    UserSocialCard,
  },
})
export default class MastersTable extends Mixins(CourseGroupMonthChainMixin, NotifyMixin, PermissionsMixin) {
  private footerOptions = tableFooterOptions
  private courses: NameValueChildrenResource[] = []
  private showSmartSearchMasters = false

  private get filter () {
    return ManagerMastersModule.mastersFilter
  }

  private set filter (filter: IUsersFilter) {
    if (!isEqual(filter, this.filter) || this.users.length === 0) {
      ManagerMastersModule.setMastersFilter(filter)
      this.fetchMasters()
    }
  }

  private get menu () {
    return [
      {
        name: 'Умный поиск',
        value: MenuActions.SMART_SEARCH,
      },
      {
        name: 'Выгрузить в excel',
        value: MenuActions.EXCEL,
      },
    ]
  }

  private get users () {
    return ManagerMastersModule.masters.data
  }

  private get masterStatuses () {
    return DictionaryModule.userStatuses
  }

  private get pagination () {
    return ManagerMastersModule.masters.meta
  }

  private get groups () {
    return DictionaryModule.groupTypes
  }

  private get onlySmartSearchAvailable () {
    return this.hasPermission(this.Permission.USERS_MASTER_SEARCH) && !this.hasPermission(this.Permission.USERS_MASTER_LIST)
  }

  private mounted () {
    if (this.onlySmartSearchAvailable) {
      this.showSmartSearchMasters = true
    }
  }

  private formatCity (cityName: string) {
    return cityName.slice(3)
  }

  private formatPhone (phone: string) {
    if (phone.length === 0) {
      return ''
    }
    return '+7' + ' ' + phone.substring(1, 4) + ' ' +
      phone.substring(4, 7) + ' ' +
      phone.substring(7, 9) + ' ' +
      phone.substring(9, 11)
  }

  @Bind
  @Debounce(300)
  private fetchMasters () {
    if (!this.onlySmartSearchAvailable) {
      ManagerMastersModule.fetchMasters()
        .catch(this.notifyError)
    }
  }

  private courseHandler (course: NameValueChildrenResource) {
    this.handleChangeCourse(course, this.removeMonthsAndGroups)
    this.handleFilter('courseId', course ? course.value as never : course as never)
  }

  private removeMonthsAndGroups () {
    this.filter.monthId = undefined
    this.filter.groupType = undefined
  }

  private handleFilter (field: IUsersFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleExport () {
    openNewTab('/manager/users/export', this.filter)
  }

  private handleMenuClick (action: string) {
    switch (action) {
    case MenuActions.EXCEL:
      this.handleExport()
      break
    case MenuActions.SMART_SEARCH:
      this.showSmartSearchMasters = true
      break
    }
  }

  private handleSelectMaster (master: UserManagerResource) {
    if (this.hasPermission(this.Permission.USERS_VIEW)) {
      this.$router.push({
        name: 'manager.users.masters.item',
        params: { masterUUID: master.uuid },
      })
    }
  }

  private get headers () {
    return [
      {
        sortable: false,
        text: 'Мастер',
        value: 'master',
      },
      {
        sortable: false,
        text: 'Почта',
        value: 'email',
      },
      {
        sortable: false,
        text: 'Телефон',
        value: 'phone',
      },
      {
        sortable: false,
        text: 'Город',
        value: 'city',
      },
      {
        sortable: false,
        text: 'Статус',
        value: 'status',
      },
    ]
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Список мастеров',
    }
  }

  @Watch('filter.courseId')
  watchCourseId(courseId: number, prevCourseId: number) {
    if (courseId !== prevCourseId) {
      this.removeMonthsAndGroups()
    }
  }
}
