








import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

import MastersTable from '@/components/tables/MastersTable.vue'

@Component({
  components: { MastersTable },
})
export default class MastersList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Мастера',
    }
  }
}
