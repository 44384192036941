import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import { IUsersFilter, ManagerUsersGetRequest, UserManagerResourcePaginator, UserType } from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'ManagerMasters',
  namespaced: true,
  store,
})
class ManagerMasters extends VuexModule {
  mastersFilter: IUsersFilter = { ...GET_DEFAULT_TABLE_FILTER(), as: UserType.MASTER, itemsPerPage: 10 }
  masters: UserManagerResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setMastersFilter (payload: IUsersFilter) {
    this.mastersFilter = Object.assign({}, payload)
  }

  @Mutation
  setMasters (payload: UserManagerResourcePaginator) {
    this.masters = payload
  }

  @Action({ rawError: true })
  async fetchMasters () {
    const { data } = await ManagerUsersGetRequest(tableOptionsToParams(this.mastersFilter))
    this.setMasters(data)
    return data
  }

}

const ManagerMastersModule = getModule(ManagerMasters)

export default ManagerMastersModule
